import React from "react";
import PropTypes from "prop-types";

import BoxWrapper from "@components/BoxWrapper";
import PortableText from "@components/PortableText";

const TextBox = ({
  className,
  whiteBox,
  hyphenate,
  title,
  subtitle,
  _rawContent,
}) => {
  return (
    <BoxWrapper
      {...{ title, subtitle, whiteBox, hyphenate }}
      className={className}
    >
      <PortableText content={_rawContent} />
    </BoxWrapper>
  );
};

TextBox.propTypes = {
  className: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  whiteBox: PropTypes.bool,
  content: PropTypes.array,
  hyphenate: PropTypes.bool,
};

export default TextBox;
