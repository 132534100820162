import React from "react";
import PropTypes from "prop-types";
import BlockContent from "@sanity/block-content-to-react";
import cx from "classnames";

import Text from "@components/Text";
import Button from "@components/Button";

import * as styles from "./styles.module.css";

const PortableText = ({ className, content, ...props }) => {
  const BlockRenderer = props => {
    const { style = "normal" } = props.node;

    if (style === "normal") {
      return (
        <Text className={styles.body} variant="body">
          {props.children}
        </Text>
      );
    }

    if (style === "h3") {
      return (
        <Text className={styles.titleThree} as="h3" variant="titleThree">
          {props.children}
        </Text>
      );
    }

    return BlockContent.defaultSerializers.types.block(props);
  };

  const link = props => {
    return (
      <Button
        as="a"
        href={props.mark.href}
        target="_blank"
        rel="noreferrer noopener"
      >
        {props.children}
      </Button>
    );
  };

  return (
    <BlockContent
      className={cx(styles.textContainer, className)}
      renderContainerOnSingleChild={true}
      serializers={{ types: { block: BlockRenderer }, marks: { link } }}
      blocks={content}
      {...props}
    />
  );
};

PortableText.propTypes = {
  className: PropTypes.string,
  content: PropTypes.array,
};

export default PortableText;
