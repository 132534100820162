import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import BackgroundImage from "gatsby-background-image";

import * as styles from "./styles.module.css";

const Section = ({
  as: Element = BackgroundImage,
  backgroundImageSources,
  backgroundColor,
  children,
  className,
  noPadding,
  ...props
}) => {
  let hasColorBackground = false;

  if (typeof Element === "function") {
    props = {
      Tag: "section",
      fluid: backgroundImageSources,
      preserveStackingContext: true,
      ...props,
    };
  } else {
    hasColorBackground = true;
  }

  return (
    <Element
      className={cx(className, styles.section, {
        [styles.colorBackground]: hasColorBackground,
      })}
      style={{ backgroundColor: hasColorBackground && backgroundColor }}
      {...props}
    >
      <div className={noPadding ? null : styles.innerWrapper}>{children}</div>
    </Element>
  );
};

Section.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  backgroundImageSources: PropTypes.array,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default Section;
