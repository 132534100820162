import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import { convertBgImageToSources } from "@utils/templateHelpers";

import Section from "@components/Section";
import Text from "@components/Text";

import * as styles from "./styles.module.css";

const Hero = ({ className, title, backgroundImage, children }) => {
  const sources = convertBgImageToSources(
    backgroundImage,
    "(min-width: 768px)",
  );

  return (
    <Section
      backgroundImageSources={sources}
      className={cx(styles.hero, className)}
    >
      <Text as="h2" variant="titleOne" className={styles.tagline}>
        {title}
      </Text>

      {children}
    </Section>
  );
};

Hero.propTypes = {
  overlap: PropTypes.object,
  backgroundImage: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default Hero;
