import { convertToBgImage } from "gbimage-bridge";

export const convertBgImageToSources = (backgroundImage, media) => {
  const mobileImage = backgroundImage?.xs?.asset?.gatsbyImageData;
  const desktopImage = backgroundImage?.lg?.asset?.gatsbyImageData;

  const mobileBgImage = convertToBgImage(mobileImage);
  const desktopBgImage = convertToBgImage(desktopImage);

  return [
    mobileBgImage.fluid,
    {
      ...desktopBgImage.fluid,
      media,
    },
  ];
};

export const getRatio = ({ width = 16, height = 9 }) => {
  return height / width;
};
