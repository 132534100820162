import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { hyphenateSync } from "hyphen/no";

import Text from "@components/Text";

import * as styles from "./styles.module.css";

const BoxWrapper = ({
  className,
  whiteBox = true,
  title,
  subtitle,
  children,
  hyphenate = false,
  ...props
}) => {
  return (
    <div
      className={cx(className, styles.textBox, {
        [styles.whiteBox]: whiteBox,
      })}
      {...props}
    >
      {title && (
        <Text as="h3" variant="titleFour" className={styles.title}>
          {hyphenate ? hyphenateSync(title) : title}
        </Text>
      )}
      {subtitle && (
        <Text as="h4" variant="titleTwo" className={styles.subtitle}>
          {hyphenate ? hyphenateSync(subtitle) : subtitle}
        </Text>
      )}
      {children}
    </div>
  );
};

BoxWrapper.propTypes = {
  className: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any.isRequired,
  whiteBox: PropTypes.bool,
  hyphenate: PropTypes.bool,
};

export default BoxWrapper;
